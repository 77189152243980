import { useSelector } from "react-redux"
import { useMemo } from "react"
import { getPermissionsByRole } from "@core/types/domain/user/UserPermission"
import { loginState, userRoleSelector } from "./loginReducer"

export function useLoginState() {
  return useSelector(loginState)
}

export function useUserRole() {
  return useSelector(userRoleSelector)
}

export function useUserPermissions() {
  const { user } = useLoginState()
  return useMemo(() => getPermissionsByRole(user?.role, user?.id), [user])
}
