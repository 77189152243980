import { queryParams, withQueryParams } from "~/utils/jsUtils"

const appBase = "/app"
const debugDetection = `${appBase}/debug-detection`
export const AppRoute = {
  Reports: `${appBase}/reports`,
  ExcelReports: `${appBase}/reports/excel`,
  SocialDashboard: `${appBase}/digital`,
  Checker: `${appBase}/checker`,
  LogoDetectionDetail: {
    path: `${appBase}/logo-detection/:streamId`,
    create: (streamId: number) => `${appBase}/logo-detection/${streamId}`,
  },
  LivestreamOverview: `${appBase}/livestreams`,
  Livestream: {
    path: `${appBase}/livestreams/:streamId`,
    create: (streamId: number) => `${appBase}/livestreams/${streamId}`,
  },
  LivestreamAdmin: {
    path: `${appBase}/livestreams/:streamId/admin`,
    create: (streamId: number) => `${appBase}/livestreams/${streamId}/admin`,
  },
  ExternalLivestream: {
    path: `/external/stream/:streamId`,
    create(streamId: number, code: string, project?: number) {
      const query = queryParams({ id: streamId, code, project })
      return `/external/stream?${query}`
    },
  },
  ExternalLivestreams: {
    path: `/external/streams/`,
    create: (projectId: number, code: string) =>
      `/external/streams?project=${projectId}&code=${code}`,
  },
  ExternalLiveStreamNew: {
    path: `/external/live/`,
    create: (projectId: number, code: string) =>
      `/external/live?project=${projectId}&code=${code}`,
  },
  DebugDetection: debugDetection,
  DebugLiveDetections: `${debugDetection}/live`,
  DebugLiveDetectionJob: {
    path: `${debugDetection}/live/:jobId`,
    create: (jobId: string) => `${debugDetection}/live/${jobId}`,
  },
  DebugDetectionStatus: `${debugDetection}/status`,
  DebugRecordingStatus: {
    path: `${debugDetection}/recording`,
    create: (params: { stream?: number; project?: number } = {}) =>
      withQueryParams(`${debugDetection}/recording`, params),
  },
  DebugDetectionModels: `${debugDetection}/models`,
  DetectionProject: `${appBase}/detection/project`,
  DetectionLivestreams: `${appBase}/detection/streams`,
  StreamCharts: `${appBase}/stream/charts`,
  DetectionLivestream: {
    path: `${appBase}/detection/stream`,
    create: (streamId: number) =>
      `${appBase}/detection/stream/${streamId ? "?stream=" + streamId : ""}`,
  },
  FootageUpload: `${appBase}/footage`,
  AdminSignals: `${appBase}/admin/signals`,
  AdminSignal: {
    path: `${appBase}/admin/signals/:signal`,
    create: (signalId: number) => `${appBase}/admin/signals/${signalId}`,
  },
  Posts: `${appBase}/posts`,
}

const pdfBase = "/pdf"
export const PdfRoute = {
  Dashboard: `${pdfBase}/dashboard`,
  LogoDashboard: `${pdfBase}/logos`,
  SocialLogoDashboard: `${pdfBase}/social-logos`,
}
